
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PlanFlag',
  props: {
    country: { type: String, required: true, default: '' },
    width: { type: [String, Number], default: 26 },
    height: { type: [String, Number], default: 16 }, 
  },
  setup(props) {
    const getFlag = (iso) => {
      switch (iso) {
        case 'us':
          return require('@/resources/assets/icons/us-flag.svg');
        case 'mx':
          return require('@/resources/assets/icons/mx-flag.svg');
        case 'ca':
          return require('@/resources/assets/icons/ca-flag.svg');
        case 'es':
          return require('@/resources/assets/icons/es-flag.svg');
        case 'it':
          return require('@/resources/assets/icons/it-flag.svg');
        case 'pt':
          return require('@/resources/assets/icons/pt-flag.svg');
        case 'de':
          return require('@/resources/assets/icons/de-flag.svg');
        case 'fr':
          return require('@/resources/assets/icons/fr-flag.svg');
        case 'nl':
          return require('@/resources/assets/icons/nl-flag.svg');
        case 'ro':
          return require('@/resources/assets/icons/ro-flag.svg');
        case 'be':
          return require('@/resources/assets/icons/be-flag.svg');
        case 'ee':
          return require('@/resources/assets/icons/ee-flag.svg');
        case 'ie':
          return require('@/resources/assets/icons/ie-flag.svg');
        case 'gr':
          return require('@/resources/assets/icons/gr-flag.svg');
        case 'hr':
          return require('@/resources/assets/icons/hr-flag.svg');
        case 'cy':
          return require('@/resources/assets/icons/cy-flag.svg');
        case 'lv':
          return require('@/resources/assets/icons/lv-flag.svg');
        case 'gb':
          return require('@/resources/assets/icons/gb-flag.svg');
        case 'bg':
          return require('@/resources/assets/icons/bg-flag.svg');
        case 'cz':
          return require('@/resources/assets/icons/cz-flag.svg');
        case 'dk':
          return require('@/resources/assets/icons/dk-flag.svg');
        case 'lt':
          return require('@/resources/assets/icons/lt-flag.svg');
        case 'lu':
          return require('@/resources/assets/icons/lu-flag.svg');
        case 'hu':
          return require('@/resources/assets/icons/hu-flag.svg');
        case 'mt':
          return require('@/resources/assets/icons/mt-flag.svg');
        case 'at':
          return require('@/resources/assets/icons/at-flag.svg');
        case 'pl':
          return require('@/resources/assets/icons/pl-flag.svg');
        case 'si':
          return require('@/resources/assets/icons/si-flag.svg');
        case 'sk':
          return require('@/resources/assets/icons/sk-flag.svg');
        case 'fi':
          return require('@/resources/assets/icons/fi-flag.svg');
        case 'se':
          return require('@/resources/assets/icons/se-flag.svg');
      }
    };

    const computedWidth = `${props.width}px`;
    const computedHeight = `${props.height}px`;

    return {
      getFlag,
      computedWidth,
      computedHeight,
    };
  },
});
