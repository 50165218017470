
import { defineComponent } from 'vue';
import {
  CheckCircleIcon,
  DocumentArrowDownIcon,
  EllipsisHorizontalCircleIcon,
  EllipsisHorizontalIcon,
  GlobeAltIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/vue/24/solid';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import ModalConfirm from '@/components/modals/ConfirmModal.vue';
import ModalLoadNotFound from '@/components/modals/LoadNotFoundModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';
import ReassignComponent from '@/modules/planning/loadDetail/_components/Reassign.vue';
import ShipmentDataComponent from '@/modules/planning/newLoad/steps/ShipmentDataStep.vue';
import ShipperLoadDetailAssignment from './assignment/ShipperLoadDetailAssignment.vue';
import ShipperLoadDetailCourse from './course/ShipperLoadDetailCourse.vue';
import ShipperLoadDetailFinished from './finished/ShipperLoadDetailFinished.vue';
import ShipperLoadDetailQuoting from './quoting/ShipperLoadDetailQuoting.vue';
import SmallModalMap from './map/SmallModalMap.vue';
import SmallModalSuccess from '@/modules/planning/loadDetail/success/SmallModalSuccess.vue';
import TemplateNameModal from '@/modules/planning/loadDetail/_components/TemplateNameModal.vue';
// Components LTL
import ShipperLoadLTLFreightModal from '@/modules/planning/loadDetail/ltl/ShipperLoadLTLFreightModal.vue';
import SkeletonLoadDetailHeaderComponent from '@/components/skeletons/LoadDetailHeaderSkeleton.vue';
import SkeletonLoadDetailNavComponent from '@/components/skeletons/LoadDetailNavSkeleton.vue';
// Utils
import { stringToShortDate } from '@/utils/formatDates';
import { truncateText } from '@/utils/formatString';
import PlanDefaultCityInformation from '@/components/defaults/PlanDefaultCityInformation.vue';
import LoadAlertsInfo from '@/components/loads/LoadAlertsInfo.vue';
import PlanIcon from '@/components/icons/PlanIcon.vue';

interface Stop {
  executionDate: string;
  location: {
    id: number;
    city: string;
    state: {
      iso: string;
    };
    zip: string;
  };
}

export default defineComponent({
  name: 'ShipperLoadDetailContent',
  components: {
    PlanIcon,
    LoadAlertsInfo,
    PlanDefaultCityInformation,
    CheckCircleIcon,
    DocumentArrowDownIcon,
    EllipsisHorizontalCircleIcon,
    EllipsisHorizontalIcon,
    EllipsisVerticalIcon,
    GlobeAltIcon,
    ModalConfirm,
    ModalLoadNotFound,
    PlanButton,
    ReassignComponent,
    ShipmentDataComponent,
    ShipperLoadDetailAssignment,
    ShipperLoadDetailCourse,
    ShipperLoadDetailFinished,
    ShipperLoadDetailQuoting,
    ShipperLoadLTLFreightModal,
    SkeletonLoadDetailHeaderComponent,
    SkeletonLoadDetailNavComponent,
    SmallModalMap,
    SmallModalSuccess,
    XCircleIcon,
    TemplateNameModal,
  },
  mixins: [BaseComponent],
  data() {
    return {
      allowed: true,
      showContentPickUp: false,
      showContentDeliverie: false,
      currentView: '',
      mapModalOpened: false,
      loadNotFound: false,
      showList: false,
      isLoaded: false,
      isSynchronizing: false,
      showReassign: false,
      showEdit: false,
      showTemplate: false,
      showModify: false,
      showCancel: false,
      showDelete: false,
      showTemplateNameModal: false,
      templateModalText: this.$t('template-confirm'),
      modifyModalText: this.$t('modify-confirm'),
      cancelModalText: this.$t('cancel-confirm'),
      deleteModalText: this.$t('delete-confirm'),
      query: {},
      prevInfoLoad: null as any,
    };
  },
  props: {
    loadId: {
      type: Number,
      required: true,
    },
    loadDetailType: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
    },
    newPlanning: { type: Boolean, required: false, default: false },
  },
  watch: {
    loadId() {
      this.$store.dispatch(
        `LoadsStore/${this.isSuperAdminRoute ? 'loadByIdAdmin' : 'loadById'}`,
        this.loadId,
      );
      if (!this.$route.fullPath.includes('day')) {
        this.$emit('update');
      }
    },
    currentStatus(): any {
      if (!this.showEdit) {
        if (this.currentStatus === 'CONFIRMED' && this.currentLoad?.shipment?.assignedCompany) {
          this.currentView = 'assignment';
        }
        if (this.currentStatus === 'QUOTING' || this.isCanceled) {
          this.currentView = 'quoting';
        }
        if (this.currentStatus === 'ASSIGNED') {
          this.currentView = 'assignment';
        }
        if (this.currentStatus === 'IN_PROGRESS') {
          this.currentView = 'course';
        }
        if (this.currentStatus === 'DELIVERED' || this.currentStatus === 'FINISHED') {
          this.currentView = 'finished';
        }
      }
    },
    currentLoad: {
      handler(newVal, oldVal) {
        if (newVal.invoices && oldVal.invoices && newVal.id === oldVal.id) {
          let invoicesHasBeenModified = false;
          const objectsById = new Map();

          newVal.invoices.forEach((obj) => {
            objectsById.set(obj.id, obj);
          });

          for (const obj of oldVal.invoices) {
            if (objectsById.has(obj.id)) {
              if (objectsById.get(obj.id).status !== obj.status) {
                invoicesHasBeenModified = true;
              }
            }
          }

          const alerts = this.$refs.alerts as any;
          if (invoicesHasBeenModified && alerts) {
            alerts.getClaimsTooltipExternal();
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    collapsedSidebar() {
      return this.$store.getters['UserStore/getCollapsedSidebar'];
    },
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    commodityMasters(): any {
      let totalWeight = 0;
      let totalWeightStr = this.$t('total-weight');

      const commodityString = this.currentLoad?.commodityMasters
        .map((item) => {
          totalWeight += item.weight;
          return `${item.commodityName} ${this.formatWeight(item.weight)}`;
        })
        .join(', ');

      return `${commodityString} - ${totalWeightStr}: ${this.formatWeight(`${totalWeight}`)}`;
    },
    loadType(): any {
      let type = this.loadDetailType;

      if (!this.loadDetailType) {
        if (this.prevInfoLoad.id === this.loadId) {
          type = this.prevInfoLoad.shipment.type;
        }
      }

      return type;
    },
    shipmentType(): any {
      if (this.currentLoad && this.currentLoad.shipment && this.currentLoad.shipment.type) {
        return this.currentLoad.shipment.type;
      } else {
        return null;
      }
    },
    totalWeight(): any {
      return this.currentLoad.freight.reduce((total, item) => total + item.weight, 0);
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    showIncidenceModal(): any {
      return this.$store.getters['LoadsStore/showIncidenceModal'];
    },
    stopSelected(): any {
      return this.$store.getters['LoadsStore/stopSelected'];
    },
    section(): string {
      return this.$store.getters['GlobalStore/getSection'];
    },
    isDetailRoute(): any {
      return this.$route.name === 'loadDetail';
    },
    stringToShortDate(): any {
      return stringToShortDate;
    },
    truncateText(): any {
      return truncateText;
    },
    currentStatus(): any {
      return this.currentLoad ? this.currentLoad.status : null;
    },
    isReassign(): boolean {
      return this.currentLoad.status === 'ASSIGNED';
    },
    isModify(): boolean {
      return this.currentStatus === 'CONFIRMED' || this.currentStatus === 'QUOTING';
    },
    isCanceled(): boolean {
      return this.currentLoad.status === 'CANCELED';
    },
    statusToCancel(): boolean {
      return (
        this.currentLoad.status === 'QUOTING' ||
        this.currentLoad.status === 'CONFIRMED' ||
        this.currentLoad.status === 'ASSIGNED'
      );
    },
    statusToDelete(): boolean {
      return this.currentLoad.status === 'QUOTING' || this.currentLoad.status === 'CONFIRMED';
    },
    currentComponent(): any {
      switch (this.currentView) {
        case 'quoting':
          return ShipperLoadDetailQuoting;
        case 'assignment':
          return ShipperLoadDetailAssignment;
        case 'course':
          return ShipperLoadDetailCourse;
        case 'finished':
          return ShipperLoadDetailFinished;
        default:
          return ShipperLoadDetailQuoting;
      }
    },
    showCheck(): any {
      let show = '';
      if (this.currentStatus !== 'QUOTING') {
        show = 'quoting';
      }
      if (this.currentStatus === 'ASSIGNED') {
        show = 'assignment';
      }
      if (
        this.currentStatus !== 'QUOTING' &&
        this.currentStatus !== 'CONFIRMED' &&
        this.currentStatus !== 'ASSIGNED'
      ) {
        show = 'assignment';
      }
      if (
        this.currentStatus !== 'QUOTING' &&
        this.currentStatus !== 'CONFIRMED' &&
        this.currentStatus !== 'ASSIGNED' &&
        this.currentStatus !== 'IN_PROGRESS'
      ) {
        show = 'course';
      }
      if (this.currentStatus === 'FINISHED') {
        show = 'finished';
      }
      if (this.isCanceled) {
        show = '';
      }

      return show;
    },
    laneId(): any {
      return this.$route.params.laneId;
    },
    projectId(): any {
      return this.$route.params.projectId;
    },
    currentLoad(): any {
      if (!this.isLoaded && this.prevInfoLoad && this.prevInfoLoad.id === this.loadId) {
        return this.prevInfoLoad;
      }
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    showFreightInfoModal(): any {
      return this.$store.getters['LoadsStore/showFreightInfoModal'];
    },
    remainDistance(): any {
      if (this.currentLoad) {
        return this.currentLoad?.shipment?.remainingDistance;
      }
      return null;
    },
    initialPickUpStop(): Stop | null {
      let initialStop = null;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL') {
          initialStop = stop;
        }
      });
      return initialStop;
    },
    finalDeliveryStop(): Stop | null {
      let finalStop = null;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL') {
          finalStop = stop;
        }
      });
      return finalStop;
    },
    pickUpStops(): any {
      let initialStops: any = [];
      const stops = this.currentLoad.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      stopsOrdered.forEach((stop: any) => {
        if (stop.type === 'PICKUP_INITIAL' || stop.type === 'PICKUP') {
          initialStops.push(stop);
        }
      });
      return initialStops;
    },
    deliveryStops(): any {
      let finalStops: any = [];
      const stops = this.currentLoad.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      stopsOrdered.forEach((stop: any) => {
        if (stop.type === 'DELIVERY_FINAL' || stop.type === 'DELIVERY') {
          finalStops.push(stop);
        }
      });
      return finalStops;
    },
    pickUpQuantity(): any {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'PICKUP') {
          sum++;
        }
      });
      return sum;
    },
    deliveriesQuantity(): any {
      let sum = 1;
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      this.currentLoad.stops.forEach((stop: any) => {
        if (stop.type === 'DELIVERY') {
          sum++;
        }
      });
      return sum;
    },
    orderedStops(): any {
      const stops = this.currentLoad?.stops;
      return stops;
    },
    specialRequirements(): any {
      if (
        this.currentLoad?.specialRequirement &&
        Object.keys(this.currentLoad?.specialRequirement).length !== 0
      ) {
        const requirements = Object.keys(this.currentLoad?.specialRequirement).filter(
          (key) => this.currentLoad?.specialRequirement[key] === true,
        );
        requirements.forEach((req, index) => {
          if (req === 'specialPermissions') {
            requirements[index] =
              this.$t(req) +
              ': ' +
              this.currentLoad?.specialRequirement?.specialPermissionsDescription;
          } else {
            requirements[index] = this.$t(req);
          }
        });
        return requirements.join(', ');
      }
      return null;
    },
    isFinishingLoad(): any {
      if (!this.currentLoad.stops || this.currentLoad.stops === undefined) return;
      const stopsLength = this.currentLoad.stops.length;
      const stops = this.currentLoad.stops;
      const stopsOrdered = stops.sort((a, b) => a.sequence - b.sequence);
      // If the Penultimate load have MediaObject and the Last load have Media Object, the load status is Finishing
      return (
        (stopsOrdered[stopsLength - 2].mediaObjects.length > 0 &&
          stopsOrdered[stopsLength - 1].mediaObjects.length === 0) ||
        this.currentLoad.status === 'FINISHED'
      );
    },
    newLoadTemplates(): any {
      return this.$store.getters['LoadsStore/getSaveLoads'];
    },
    isTemplate(): any {
      return this.newLoadTemplates.find((template) => template.load.id === this.loadId);
    },
    rateConfirmation(): any {
      return this.currentLoad?.shipment?.mediaObjects?.find(
        (item: any) => item.type === 'RATE_CONFIRMATION',
      );
    },
  },
  async created() {
    this.prevInfoLoad = JSON.parse(localStorage.getItem('loadDetailPreviousData') || '[]');

    if (this.isEditing) {
      this.showEdit = true;
    }
    this.loadId;
    await this.$store
      .dispatch(`LoadsStore/${this.isSuperAdminRoute ? 'loadByIdAdmin' : 'loadById'}`, this.loadId)
      .then(async (response) => {
        if (this.$refs.shipmentData as any) {
          (this.$refs.shipmentData as any).setLoad(this.currentLoad);
        }
        if (this.isEditing) {
          this.showEdit = true;
        }
        if (response?.laneRFP) {
          await this.$store.dispatch('RFPStore/getLaneById', response?.laneRFP?.id).finally(() => {
            this.isLoaded = true;
          });
        } else {
          this.isLoaded = true;
        }
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 404) {
          this.loadNotFound = true;
        }
      });

    //TODO CHANGE IF BACKED ADD AN ASSIGNED COMPANY BY DEFAULT OR SOMETHING
    if (!this.currentLoad.shipment.assignedCompany && this.currentLoad.source === 'RFP') {
      this.currentView = 'quoting';
      return;
    }

    if (this.currentLoad.shipment.assignedCompany && this.currentLoad.source === 'RFP') {
      this.currentView = 'assignment';
      return;
    }

    if (this.currentStatus === 'FINISHED') {
      this.currentView = 'finished';
    }
    if (this.currentStatus === 'CONFIRMED') {
      this.currentView = 'assignment';
    }
    if (this.currentStatus === 'ASSIGNED') {
      this.currentView = 'assignment';
    }
    if (this.currentStatus === 'IN_PROGRESS') {
      this.currentView = 'course';
    }
    if (this.currentStatus === 'QUOTING' || this.isCanceled) {
      this.currentView = 'quoting';
    }
    if (this.currentStatus === 'FINISHED') {
      this.currentView = 'finished';
    }
    const query = {
      page: 1,
    };

    if (!this.isSuperAdminRoute) this.$store.dispatch('LoadsStore/getSaveLoads', query);
  },
  methods: {
    closeModalIncidence() {
      this.$store.dispatch('LoadsStore/showIncidenceModal', false);
      this.$store.dispatch('LoadsStore/stopSelected', null);
    },
    hideContent(type) {
      if (type === 'pickup') {
        this.showContentPickUp = false;
      } else {
        this.showContentDeliverie = false;
      }
    },
    filteredPickupsCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'PICKUP_INITIAL' || obj.type === 'PICKUP') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    filteredDeliveriesCompleted(load): any {
      const filteredObjects = load.filter(
        (obj) => (obj.type === 'DELIVERY_FINAL' || obj.type === 'DELIVERY') && obj.doneAt,
      ).length;
      return filteredObjects;
    },
    // showModifyLoad() {
    //   if (this.isModify) {
    //     this.showModify = true;
    //   }
    // },
    modifyLoad() {
      // this.showModify = false;
      if (!this.showReassign && this.isModify && !this.isSuperAdminRoute) {
        this.showEdit = true;
        setTimeout(() => {
          if (this.$refs.shipmentData as any) {
            (this.$refs.shipmentData as any).setLoad(this.currentLoad);
          }
        }, 100);
      }
    },
    submitModifyLoad(data: any) {
      console.log(this.allowed, '');

      if (!data) {
        this.showEdit = false;
        // this.showModify = false;
        if (this.isEditing) {
          this.closeModal();
        }
      } else {
        this.allowed = false;

        this.$store.dispatch('LoadsStore/modifyLoad', data).then((res) => {
          let localStorageArray = JSON.parse(localStorage.getItem('loadStops') || '[]');

          let indexToDelete = localStorageArray.findIndex(
            (item) => item.loadId === this.currentLoad.id,
          );

          if (indexToDelete !== -1) {
            localStorageArray.splice(indexToDelete, 1);
            localStorage.setItem('loadStops', JSON.stringify(localStorageArray));
          }

          this.allowed = true;
          this.$store
            .dispatch('LoadsStore/loadById', this.currentLoad.id)
            .then(() => {
              // const query = this.filters;
              if (this.filters !== undefined) {
                var query = this.filters;
                query.page = 1;
              } else {
                query = { page: 1 };
              }
              this.$store.dispatch('LoadsStore/loads', query).then(() => {
                BaseComponent.methods?.showToastSuccess(this.$t('message-load-modified'));
                this.showEdit = false;
                // this.showModify = false;
                if (this.isEditing) {
                  this.closeModal();
                }
              });
            })
            .catch((err) => {
              console.log(err);
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              this.showEdit = false;
              // this.showModify = false;
            });
          //   this.showEdit = false;
          // this.showModify = false;
        });
      }
    },
    cancelEdit() {
      this.showEdit = false;
      this.$store
        .dispatch('LoadsStore/loadById', this.currentLoad.id)
        .then(() => {
          if (this.isEditing) {
            this.closeModal();
          }
        })
        .catch((err) => {
          console.log(err);
          BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          this.showEdit = false;
        });
    },
    cancelLoad() {
      if (!this.isCanceled && this.statusToCancel && !this.isSuperAdminRoute) {
        this.showCancel = true;
      }
    },
    submitCancelLoad(confirm: boolean) {
      if (confirm) {
        this.showCancel = false;
        this.$store
          .dispatch('LoadsStore/cancelLoad', this.currentLoad.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-load-cancelled'));
            if (this.filters !== undefined) {
              var query = this.filters;
            } else {
              query = { page: 1 };
            }
            this.updateTotalStatus();
            this.$store.dispatch('LoadsStore/loads', query).then(() => {
              this.closeModal();
            });
          })
          .catch((err) => {
            // console.log(err);
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
          });
      } else {
        this.showCancel = false;
      }
    },

    deleteLoad() {
      this.showDelete = true;
    },
    submitDeleteLoad(confirm: boolean) {
      if (confirm) {
        this.showDelete = false;
        this.$store
          .dispatch('LoadsStore/deleteLoad', this.currentLoad.id)
          .then(() => {
            BaseComponent.methods?.showToastSuccess(this.$t('message-load-deleted'));
            if (this.filters !== undefined) {
              var query = this.filters;
            } else {
              query = { page: 1 };
            }
            this.updateTotalStatus();
            this.$store.dispatch('LoadsStore/loads', query).then(() => {
              this.closeModal();
            });
          })
          .catch((err) => {
            console.log(err);
            BaseComponent.methods?.showToastError(err?.response?.data?.detail);
            this.closeModal();
          });
      } else {
        this.showDelete = false;
      }
    },
    showTemplateLoad() {
      if (!this.isTemplate && !this.isSuperAdminRoute) {
        this.showTemplate = true;
      }
    },
    templateLoad(data: any) {
      if (!data) {
        this.showTemplate = false;
      } else {
        this.createTemplate();
      }
    },
    createTemplate() {
      if (!this.isTemplate) {
        this.showTemplateNameModal = true;
      }
    },
    updateTotalStatus() {
      this.$store.dispatch('LoadsStore/loadsTotals', this.filters).catch((err) => console.log(err));
    },
    closeModal() {
      if (
        this.$store.state.UserStore.previousRouteImpersonating &&
        this.$store.state.UserStore.previousRouteImpersonating.includes('PlanningRFP')
      ) {
        this.$store.commit('UserStore/setPreviousRoute', '');
        this.$router.go(-1);
      }

      if (this.newPlanning) {
        this.$emit('hideModal');
        return;
      }

      this.$store.commit('GlobalStore/setSectionFromPlanification', false);
      this.currentView = '';
      if (!this.isEditing) {
        this.$emit('hideModal');
        if (this.$route.fullPath.includes('day')) {
          this.$emit('hideModal');
        } else {
          if (this.isSuperAdminRoute) {
            this.$router.push('/superadmin/loadsList');
          } else if (this.$route.name === 'laneService' || this.$route.name === 'RFP-laneService') {
            this.$router.go(-1);
          } else if (
            this.$store.state.UserStore.previousRouteImpersonating.includes('PlanningRFP')
          ) {
            this.$store.commit('UserStore/setPreviousRoute', '');
            this.$router.go(-1);
          } else {
            this.$router.push('/planning');
          }
        }
      } else {
        // Quitar ruta si se quiere permanecer en el detalle de la carga al modificar desde el menu principal
        if (this.isSuperAdminRoute) {
          this.$router.push('/superadmin/loadsList');
        } else if (this.$route.name === 'RFP-laneDetail') {
          this.$router.push({
            name: 'RFP-laneDetail',
            params: { projectId: this.projectId, laneId: this.laneId },
          });
        } else {
          this.$router.push('/planning');
        }
        this.$emit('hideModal');
      }
    },
    syncCargobot() {
      if (this.currentLoad.shipment.uploadedToCargobot) return;
      this.isSynchronizing = true;
      this.$store
        .dispatch('ShipmentStore/postCargobotUpload', this.currentLoad.shipment.id)
        .then(async () => {
          await this.$store.dispatch(
            `LoadsStore/${this.isSuperAdminRoute ? 'loadByIdAdmin' : 'loadById'}`,
            this.loadId,
          );
          BaseComponent.methods?.showToastSuccess(this.$t('synchronized-cb'));
        })
        .catch((err) => BaseComponent.methods?.showToastError(err?.response?.data?.error))
        .finally(() => (this.isSynchronizing = false));
    },
  },
});
