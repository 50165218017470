
import PlanContactTags from '@/components/labels/PlanContactTags.vue';

interface Carrier {
  type: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  prefix: string;
  phone: number;
  mcNumber: string;
  identificationNumber: string;
  dotNumber: string;
  validation: {
    passed: boolean;
    message: string;
    failedFields: string;
  };
}

import store from '@/services/store';
// Base
import BaseComponent from '@/base/BaseComponent';
import { computed, defineComponent, ref } from 'vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import EditCurrentContactModal from '@/modules/planning/contacts/ContactsImport/EditCurrentContactModal.vue';
import PlanButton from '@/components/buttons/PlanButton.vue';

// Resources
import i18n from '@/resources/locales';
import { useRoute, useRouter } from 'vue-router';

// Styles
import HorizontalScroll from '@/resources/assets/styles/animation/HorizontalScroll.vue';
export default defineComponent({
  components: { PlanContactTags, PencilSquareIcon, EditCurrentContactModal, PlanButton },
  props: {
    currentContacts: Array as () => Carrier[],
  },
  mixins: [BaseComponent, HorizontalScroll],
  setup(props, { emit }) {
    const $t = i18n.global.t;
    const contact = ref({});
    const contacts = ref(props.currentContacts);
    const route = useRoute();
    const router = useRouter();
    const loadingOmit = ref(false);
    const loadinSend = ref(false);
    const tableHeaders = ref([
      'type',
      'placeholder-company',
      'cif-carrier',
      'contacts-import.firstName',
      'lastname',
      'email',
      'prefix',
      'phone',
      'mc-number',
      'dot-number',
      'actions',
    ]);

    const currentIndex = ref(null);

    const showEditModal = ref(false);

    const handleUpdateContact = (updatedContact) => {
      if (currentIndex.value !== null && contacts.value) {
        currentContacts.value[currentIndex.value] = updatedContact;
      }
      currentIndex.value = null;
      store.dispatch('UserStore/checkContactsFromJson', currentContacts.value).then(() => {
        BaseComponent.methods?.showToastSuccess($t('edited-successfully'));
      });
    };

    const isValidContactExists = computed(() => {
      return currentContacts.value.some((contact) => contact.validation.passed);
    });

    const hasInvalidContacts = computed(() => {
      return currentContacts.value.some((contact) => !contact.validation.passed);
    });

    const currentContacts = computed(() => {
      return store.getters['UserStore/getCurrentContacts'];
    });

    const goPreviusView = () => {
      store.commit('UserStore/setCurrentContacts', []);
    };

    const getValidContacts = () => {
      return currentContacts.value.filter((contact) => contact.validation.passed);
    };

    const sendContacts = async () => {
      if (hasInvalidContacts.value) {
        BaseComponent.methods?.showToastError($t('contact-with-errors'));
        return;
      }

      if (currentContacts.value.length === 0) {
        BaseComponent.methods?.showToastError($t('contacts-empty'));
        return;
      }

      loadinSend.value = true;

      await store
        .dispatch('UserStore/createContactsFromJson', currentContacts.value)
        .then((response) => {
          if (response !== "async") {
            BaseComponent.methods?.showToastSuccess($t('created-successfully'));
            router.push('/planning/contacts');
          } else {
            store.commit('UserStore/setImportingContacts', true)
            router.push('/planning/contacts');
          }
        })
        .finally(() => {
          loadinSend.value = false;
        });
    };
    const omitInvalidSContacts = () => {
      if (!isValidContactExists.value) {
        BaseComponent.methods?.showToastError($t('contacts-all-invalid'));
        return;
      }

      if (currentContacts.value.length === 0) {
        BaseComponent.methods?.showToastError($t('contacts-empty'));
        return;
      }

      loadingOmit.value = true;
      let contactsValid = getValidContacts();

      store
        .dispatch('UserStore/createContactsFromJson', contactsValid)
        .then((response) => {
          if (response === 'async') {
            store.commit('UserStore/setImportingContacts', true)
            router.push('/planning/contacts');
          } else {
            BaseComponent.methods?.showToastSuccess($t('created-successfully'));
            router.push('/planning/contacts');
          }
        })
        .finally(() => {
          loadingOmit.value = false;
        });
    };

    // const formatErrors = (failedFields) => {
    //   const fields = failedFields.split(',');

    //   let validationMessages = '';
    //   fields.forEach((field) => {
    //     const translatedField = $t(field.trim());
    //     validationMessages += `${translatedField} ${$t('invalid')}, `;
    //   });
    //   return validationMessages;
    // };

    const modifyContact = (selectedContact, index) => {
      contact.value = Object.assign({}, selectedContact);
      currentIndex.value = index;
      showEditModal.value = true;
    };

    const deleteItem = (index: any) => {
      emit('delete', index);
    };

    return {
      omitInvalidSContacts,
      loadinSend,
      sendContacts,
      goPreviusView,
      contact,
      handleUpdateContact,
      showEditModal,
      modifyContact,
      tableHeaders,
      contacts,
      deleteItem,
      loadingOmit,
    };
  },
});
