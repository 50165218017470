
import { defineComponent } from 'vue';
import { saveAs } from 'file-saver';
// Base
import BaseComponent from '@/base/BaseComponent';
// Components
import AdditionalFeesOfCarrier from '@/modules/planning/loadDetail/finished/AdditionalFeesOfCarrier.vue';
import LoadDocuments from '@/components/loads/LoadDocuments.vue';
import PlanAvatar from '@/components/PlanAvatar.vue';
import PlanButtonIcon from '@/components/buttons/PlanButtonIcon.vue';
import PlanTabs from '@/components/tabs/PlanTabs.vue';
import Spin from '@/components/spin/AnimateBlackSpin.vue';
import UserSection from '@/modules/superAdmin/_components/UserSection.vue';

import { Store } from 'vuex';
import store from '@/services/store';

interface Invoice {
  fetchedImageOriginal: any;
  isShowPaid: any;
  invoiceStatus: any;
  isChangingPaid: boolean;
  isChangingNoPaid: boolean;
  allowed: boolean;
  isInvoice: boolean;
  sendingRequestBillAction: boolean;
  currentTab: number;
}

export default defineComponent({
  name: 'LoadDetails',
  components: {
    AdditionalFeesOfCarrier,
    LoadDocuments,
    PlanButtonIcon,
    PlanTabs,
    Spin,
    UserSection,
  },
  mixins: [BaseComponent],
  props: {
    step: {
      type: String,
      default: '',
    },
  },
  data(): Invoice {
    return {
      fetchedImageOriginal: null,
      sendingRequestBillAction: false,
      isShowPaid: [] as any,
      invoiceStatus: [] as any,
      isChangingPaid: false,
      isChangingNoPaid: false,
      allowed: true,
      isInvoice: false,
      currentTab: 0,
    };
  },
  computed: {
    isShipperViewer(): any {
      return this.$store.getters['UserStore/getIsShipperViewer'];
    },
    commodityMasters(): any {
      return this.currentLoad.commodityMasters
        .map((item) => `${item.commodityName} ${this.formatWeight(item.weight)}`)
        .join(', ');
    },
    currentLoad(): any {
      return this.$store.getters['LoadsStore/currentLoad'];
    },
    isSuplanting(): any {
      return this.$store.getters['UserStore/getImpersonatedUserId'];
    },
    currentUserImpersonating(): any {
      return this.$store.getters['UserStore/getCurrentUserImpersonating'];
    },
    currentUser(): any {
      if (this.currentUserImpersonating && this.isSuplanting) {
        return this.currentUserImpersonating;
      } else {
        return this.$store.getters['UserStore/getCurrentUser'];
      }
    },
    docsList(): any {
      let response = [] as any;
      const docs = this.$store.getters['LoadsStore/getDocsList'];
      for (const key in docs) {
        if (Object.hasOwnProperty.call(docs, key)) {
          response.push({
            documents: docs[key],
            key: key,
            title: this.$t(key),
          });
        }
      }
      return response;
    },
    assignedDriver() {
      return this.currentLoad?.shipment?.driver;
    },
    invoices(): any {
      return this.currentLoad.invoices;
    },
    isCanceled(): boolean {
      return this.currentLoad.status === 'CANCELED';
    },
    isSuperAdminRoute(): any {
      return this.$route.fullPath.includes('superadmin');
    },
  },
  created() {
    this.$store.dispatch('LoadsStore/getDocsList', this.currentLoad.id);
    if (this.currentLoad.status === 'IN_PROGRESS' || this.currentLoad.status === 'FINISHED') {
      this.currentTab = 1;
    }
  },
  methods: {
    async addMemberToChatLoad(chatId) {
      await this.$store.dispatch('ChatsStore/addMemberToChatLoad', chatId);
    },
    changeTab(value) {
      this.currentTab = value;
    },
    changePaid(data: string, invoice: any, i: number) {
      if (this.isSuperAdminRoute) {
        return;
      }
      if (this.allowed) {
        if (data === 'STATUS_PAID') {
          this.isChangingPaid = true;
        } else {
          this.isChangingNoPaid = true;
        }
        if (data === invoice.status) {
          this.closeShowPaid(i);
          return;
        }
        if (data !== invoice.status) {
          this.allowed = false;
          this.$store
            .dispatch('ShipmentStore/updateInvoiceStatus', {
              id: invoice.id,
              status: {
                status: data,
              },
            })
            .then(() => {
              this.$store.dispatch('LoadsStore/loadById', this.currentLoad.id).then(() => {
                BaseComponent.methods?.showToastSuccess(this.$t('toast-status-updated'));
                this.isShowPaid[i] = false;
                this.isChangingPaid = false;
                this.isChangingNoPaid = false;
                this.allowed = true;
              });
            })
            .catch((err) => {
              BaseComponent.methods?.showToastError(err?.response?.data?.detail);
              this.isShowPaid[i] = false;
              this.isChangingPaid = false;
              this.isChangingNoPaid = false;
              this.allowed = true;
            });
        }
      }
    },
    closeShowPaid(i) {
      this.isChangingPaid = false;
      this.isChangingNoPaid = false;
      this.isShowPaid[i] = false;
    },
    convertKeyToString(key) {
      let convertedString;
      switch (key) {
        case 'TWICCard':
          convertedString = this.$t('twic-card');
          break;
        case 'tsa':
          convertedString = this.$t('tsa-card');
          break;
        case 'hazardousLoad':
          convertedString = this.$t('hazardousLoad');
          break;
        case 'specialPermissions':
          convertedString = this.$t('specialPermissions');
          break;
        case 'bondedPermission':
          convertedString = this.$t('bondedPermission');
          break;
        case 'teamDriver':
          convertedString = this.$t('teamDriver');
          break;
        case 'tir':
          convertedString = this.$t('tir');
          break;
      }

      return convertedString;
    },
    async openChat() {
      const member = this.currentLoad?.chat?.chatMembers.find(
        (member) => member.user.id === this.currentUser?.id,
      );
      if (member) {
        member.readed = true;
      } else {
        if (this.currentLoad.chat?.id) {
          await this.addMemberToChatLoad(this.currentLoad.chat.id);
        }
      }
      this.$store.commit('GlobalStore/setOpenChat', true);
      this.$store.commit('LoadsStore/loadId', this.currentLoad.id);
      this.$store.commit(
        'ChatsStore/setChatId',
        this.currentLoad.chat ? this.currentLoad.chat.id : null,
      );
    },
    requestInvoice() {
      this.sendingRequestBillAction = true;
      this.$store
        .dispatch('LoadsStore/sendRequestBidEmail', { id: this.currentLoad.id })
        .then(() => {
          BaseComponent.methods?.showToastSuccess(this.$t('email-sent-correctly'));
        })
        .finally(() => {
          this.sendingRequestBillAction = false;
        });
    },
    showPaid(i) {
      if (this.isSuperAdminRoute) {
        return;
      }
      if (this.isShowPaid.length > 0 && this.isShowPaid[i] === true) {
        this.isShowPaid[i] = false;
      } else if (
        !this.isShowPaid.find((element) => element === true) ||
        this.isShowPaid?.length === 0
      ) {
        this.isShowPaid[i] = !this.isShowPaid[i];
      }
    },
    async viewInvoice(invoice) {
      this.isInvoice = true;
      const response = await this.$store.dispatch('ShipmentStore/getDownloadFileBlob', {
        id: invoice.mediaObject.id,
        class: invoice.mediaObject.entityClass,
      });

      const url = URL.createObjectURL(response);
      const newWindow = window.open(url, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        const fileName = `document-${invoice.mediaObject.id}`;
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(blob, fileName, { autoBom: true });
      }
      this.isInvoice = false;
    },
  },
});
